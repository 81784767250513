import { Grid } from "@mui/material";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { getGeneralInformation } from "@/api/api";
import { H3, P } from "@/components/typography";
import { GeneralInformation } from "@/types/generalInformationTypes";
import parseImageURL from "@/utils/parseImageURL";
import { isInternalLink } from "@/utils/url";

const StyledFooter = styled.footer`
  .footer-top {
    background: var(--color-teal-100);
    padding: 44px 0;

    h3 {
      color: var(--color-black);
      font-size: 22px;

      @media all and (min-width: 1200px) {
        font-size: 32px;
        white-space: nowrap;
      }
    }

    p {
      color: var(--color-black);
      transition: all 0.3s ease;
      font-size: 14px;

      @media all and (min-width: 1200px) {
        font-size: 18px;
      }
    }
  }

  .footer-bottom {
    background: ${({ theme }) => theme.colors.white.normal};
    padding: 17px 0;

    a {
      font-weight: 400;
      font-size: 18px;
      line-height: 160%;
      text-decoration-line: underline;
      color: ${({ theme }) => theme.colors.text.normal};
    }
  }

  .footer_logos_wrapper {
    display: flex;
    align-items: center;
  }

  .social_icons_wrapper p {
    display: flex;
    align-items: center;
    gap: 10px;

    /* Remove margin from first element since it there is already a margin on the top by the previous element*/

    &:first-of-type {
      margin-top: 0;
    }
  }

  .footer_bottom {
    background: var(--color-teal);
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;

    .link_wrapper {
      display: flex;
      gap: 28px;

      @media all and (min-width: 768px) {
        gap: 40px;
      }

      a,
      button {
        color: var(--color-white);
        font-size: 14px;
        font-weight: 300;

        @media all and (min-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }

  .cols_wrapper {
    display: flex;
    width: 100%;
    gap: 28px;
    flex-wrap: wrap;

    @media all and (min-width: 768px) {
      flex-wrap: nowrap;
    }

    @media all and (min-width: 1450px) {
      gap: 150px;
    }

    .__custom_col {
      display: flex;
    }

    .__col_4 {
      width: 100%;

      @media all and (min-width: 768px) {
        width: 30%;
      }
    }

    .__col_8 {
      width: 100%;
      justify-content: space-around;
      gap: 16px;
      flex-direction: column;

      @media all and (min-width: 768px) {
        width: 70%;
        flex-direction: row;
      }
    }
  }

  .logos_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .footer_logo {
      width: 200px;
      margin-bottom: 40px;

      @media all and (min-width: 1200px) {
        width: 246px !important;
        height: auto;
      }
    }
  }
`;

const AppLink = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => {
  if (isInternalLink(href)) {
    return <Link href={href}>{children}</Link>;
  }

  return (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

export default function Footer() {
  const [isLoading, setIsLoading] = useState(true);
  const [generalInformation, setGeneralInformation] =
    useState<GeneralInformation | null>(null);
  const [cookieBannerOpen, setCookieBannerOpen] = useState<boolean>(false);

  const generateLogo = () => {
    if (generalInformation?.companyLogoWhiteId) {
      const logoUrl = parseImageURL(generalInformation?.companyLogoWhiteId);
      return (
        <Image
          src={logoUrl}
          alt={generalInformation.companyName}
          width={177}
          height={88}
          className="object-cover"
        />
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    const getData = async () => {
      const data = await getGeneralInformation();
      if (data) {
        setGeneralInformation(data);
      }
      setIsLoading(false);
    };

    if (!generalInformation && isLoading) {
      getData();
    }
  }, [generalInformation, isLoading]);

  return (
    <StyledFooter>
      <div className="footer-top">
        <div className={"mx-20"}>
          <Grid container>
            <div className="cols_wrapper">
              <div className="__custom_col __col_4 justify-center text-center md:justify-start md:text-left">
                {/*  <div className="mb-[50px]">{generateLogo()}</div> */}
                <div className="logos_wrapper">
                  <Image
                    src="/footer.png"
                    alt=""
                    className="footer_logo"
                    width={0}
                    height={0}
                    sizes={"100vw"}
                    style={{ width: 246, height: "auto" }}
                  />
                  <div className="footer_logos_wrapper flex flex-wrap justify-center gap-4 md:justify-start">
                    {[
                      { logo: "/footer_logo_1.jpg" },
                      {
                        logo: "/footer_logo_2.png",
                        url: "https://cbf.nl/organisaties/villa-pinedo",
                      },
                    ].map((item) => (
                      <Image
                        key={item.logo}
                        src={item.logo}
                        alt=""
                        width={0}
                        height={0}
                        sizes={"100%"}
                        className={`h-[40px] w-auto ${item.url ? "cursor-pointer" : ""}`}
                        onClick={() =>
                          item.url && window.open(item.url, "_blank")
                        }
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="__custom_col __col_8">
                <Grid
                  item
                  xs={12}
                  md={3}
                  className={"text-center md:text-left"}
                >
                  <H3 className="mb-5">Contact</H3>
                  <P className={"font-extrabold"}>
                    {generalInformation?.companyName}
                  </P>
                  <P>{generalInformation?.companyAddress}</P>
                  <P>
                    {generalInformation?.companyZipcode}{" "}
                    {generalInformation?.companyCity}
                  </P>
                  <P>{generalInformation?.companyEmail}</P>
                  <P>{generalInformation?.companyKvk}</P>
                  <P>{generalInformation?.companyRsin}</P>
                </Grid>
                {generalInformation?.importantLinksTitle && (
                  <Grid
                    item
                    xs={12}
                    md={3}
                    className={"text-center md:text-left"}
                  >
                    <H3 className="mb-5">
                      {generalInformation?.importantLinksTitle}
                    </H3>
                    {generalInformation?.importantLinks?.map?.(
                      (item, index) => (
                        <P key={`${item.id}-${index}`}>
                          <AppLink href={item.link}>{item.name}</AppLink>
                        </P>
                      ),
                    )}
                    {!!generalInformation?.privacyUrl && (
                      <P>
                        <AppLink href={generalInformation.privacyUrl}>
                          Privacyverklaring
                        </AppLink>
                      </P>
                    )}
                    {!!generalInformation?.termsConditionUrl && (
                      <P>
                        <AppLink href={generalInformation.termsConditionUrl}>
                          Terms & Conditions
                        </AppLink>
                      </P>
                    )}
                    {!!generalInformation?.cookiesUrl && (
                      <P>
                        <AppLink href={generalInformation.cookiesUrl}>
                          Cookies
                        </AppLink>
                      </P>
                    )}
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  md={3}
                  className={"text-center md:text-left"}
                >
                  <H3 className="mb-5">Socials</H3>
                  <div className="social_icons_wrapper inline-block text-center md:text-left">
                    {!!generalInformation?.instagramUrl && (
                      <P>
                        <Image src="/in.png" alt="" width={16} height={17} />
                        <AppLink href={generalInformation.instagramUrl}>
                          Instagram
                        </AppLink>
                      </P>
                    )}
                    {!!generalInformation?.youtubeUrl && (
                      <P>
                        <Image src="/yt.png" alt="" width={18} height={16} />
                        <AppLink href={generalInformation.youtubeUrl}>
                          YouTube
                        </AppLink>
                      </P>
                    )}
                    {!!generalInformation?.linkedinUrl && (
                      <P>
                        <Image src="/ln.png" alt="" width={16} height={17} />
                        <AppLink href={generalInformation.linkedinUrl}>
                          LinkedIn
                        </AppLink>
                      </P>
                    )}
                    {!!generalInformation?.facebookUrl && (
                      <P>
                        <Image src="/fb.png" alt="" width={10} height={18} />
                        <AppLink href={generalInformation.facebookUrl}>
                          Facebook
                        </AppLink>
                      </P>
                    )}
                    {!!generalInformation?.tiktokUrl && (
                      <P>
                        <Image src="/tk.png" alt="" width={16} height={19} />
                        <AppLink href={generalInformation.tiktokUrl}>
                          TikTok
                        </AppLink>
                      </P>
                    )}
                  </div>
                </Grid>
              </div>
            </div>
          </Grid>
        </div>
      </div>
      <div className="footer_bottom">
        <div className="link_wrapper">
          <Link href="/privacyverklaring">Privacyverklaring</Link>
          <Link href="/algemene-voorwaarden">Algemene voorwaarden</Link>
          {/*<button onClick={() => setCookieBannerOpen(true)}>Cookies</button>*/}
          {/*<CookieBanner open={cookieBannerOpen} setOpen={setCookieBannerOpen} />*/}
        </div>
      </div>
    </StyledFooter>
  );
}
